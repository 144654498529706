/* global jQuery frontPageRefreshData */
(function ($, frontPageRefreshData) {
  'use strict';

  const { timeToRefreshMin } = frontPageRefreshData;

  var lastActive;
  var throttle = function (a, b) {
    /* jshint ignore:start */
    //jscs:disable
    var c, d;
    return function () {
      var e = this,
        f = arguments,
        g = +new Date();
      c && g < c + a
        ? (clearTimeout(d),
          (d = setTimeout(function () {
            (c = g), b.apply(e, f);
          }, a)))
        : ((c = g), b.apply(e, f));
    };
    //jscs:enable
    /* jshint ignore:end */
  };

  var setActive = function () {
    var currentSeconds = new Date().getTime() / 1000;
    lastActive = currentSeconds;
  };

  $(document).ready(function () {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('norefresh') === '1') {
      return;
    }

    lastActive = new Date().getTime() / 1000;

    setActive();

    setTimeout(function () {
      var currentSeconds = new Date().getTime() / 1000;

      if (currentSeconds - 15 > lastActive) {
        var url = window.location.href;
        if (url.indexOf('autorefresh') === -1) {
          if (url.indexOf('?') > -1) {
            url += '&autorefresh';
          } else {
            url += '?autorefresh';
          }
        }
        window.location.href = url;
      } else {
        setInterval(function () {
          currentSeconds = new Date().getTime() / 1000;

          if (currentSeconds - 15 > lastActive) {
            var url = window.location.href;
            if (url.indexOf('autorefresh') === -1) {
              if (url.indexOf('?') > -1) {
                url += '&autorefresh';
              } else {
                url += '?autorefresh';
              }
            }
            window.location.href = url;
          }
        }, 1000 * 15);
      }
    }, 1000 * 60 * timeToRefreshMin);
    $(window).on('scroll', throttle(1000, setActive));
  });
})(jQuery, frontPageRefreshData);
